.App {
  text-align: center;
  height: 1000vh;
  width: 1000vw;
}

.horizontal {
  height: 100vh;
  width: 1000vw;
  display: flex;

}

/* .child {
  flex: 1;
} */



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (max-width: 900px) {
  .App {
    /* display: flex; */
    /* flex-direction: column; */
    display: block;
    text-align: center;
    height: 100%;
    width: 100vw;
    overflow-x: hidden !important;
    overflow-y: scroll;
    /* background-color: var(--brown); */
  }

  .horizontal {
    width: 100vh;
    display: block;
  }
}
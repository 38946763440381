.TeamPage {
    text-align: left;
    /* width: 100vw;
    height: 100vh; */
    width: 1000px;
    /* background-color: var(--black); */
    margin-right: 50px;

}

.TeamPage-Text {
    padding-top: 25vh;
    padding-left: 8em;
}

.TeamPage h1 {
    color: var(--brown);
    padding-bottom: 1%;
}


.TeamPage .image {
    flex: 1;
    width: auto;
    height: 60vh;
}

@media screen and (max-width: 900px) {
    .image {
        width: 80vw !important;
        height: auto !important;
        margin: 0px;
        display: inline-block;

    }

    .TeamPage h1 {
        font-size: 2rem;
        margin-top: 20px;
        width: 600px;
        color: var(--brown);
    }

    .TeamPage {

        padding: 0 !important;
        /* float: left; */
        /* padding: 8px 8px; */
        width: 600px;
        height: 550px;
        background-color: #FFEECA;
    }

    .TeamPage-Text {
        padding-left: 40px !important;
        /* padding-left: 15em; */
    }

    /* .TeamPage-description {
        width: 100%;
    } */
}
/* Add CSS Stuff here! */

.landing {
  width: 100vw;
  min-height: 100vh;

  justify-content: flex-start;
  text-align: left;

}

.landing h1 {
  color: var(--orange);
  font-size: 125px;
  line-height: 90px;
}

.landing a {
  color: var(--orange);
}

.landing a:hover {
  color: var(--lightBrown);
}

/* .landing p {
    color: var(--orange);
} */

.landing .position {
  text-align: left;
  display: inline-block;
  width: fit-content;
  height: fit-content;
  margin-top: 0;
  margin-bottom: 3%;
  position: relative;
  bottom: 0;
  left: 3%;
  top: 60vh;

}

.landing .position .message {
  text-align: left;
  color: var(--orange);
  /* bottom: 200px; */
  margin: 0px;
  font-size: 1.5rem;
  left: 2.4%;
  position: inherit;

}

.date {
  /* text-align: right; */
  color: var(--orange);
  left: 60%;
  bottom: -10%;
  font-size: 1.5rem;
  position: inherit;
}
#right-arrow {
  width: 50px;
  height: auto;
  float: right;
  justify-content: center;
  align-items: center;
  top: 480px;
  display: flex;
  position: relative;
  margin-right: 2%;
}

#mlh-trust-badge {
  right: 50px;
}

#mobile-bg {
  display: none;
}

@media screen and (max-width: 600px) {

  /*
    absolute position this near the top, centered
  */
  #mobile-bg {
    display: block;
    position: absolute;
    width: 80vw;
    height: auto;
    top: -43vh;
    /* z-index: -1; */
  }
  #right-arrow {
    display: none;
  }

  .landing h1 {
    font-size: 30px;
  }

  .landing {
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--brown);
  }

  .position {
    width: 80vw !important;
  }

  .message {
    font-size: 18px !important;
    margin-bottom: -2em;
  }

  .date {
    font-size: 18px;
    left: 0 !important;
    float: right;
  }

  #mlh-trust-badge {
    right: 10px;
  }

  .mlh-wrapper {
    width: 20px;
    height: auto;
  }

}


/*
position this sticky to the bottom right. hide on mobile
*/
.toggle-graphics {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  margin: 20px;
  color: #F5AD40;
}

@media screen and (max-width: 900px) {
  .toggle-graphics {
    display: none;
  }
  #right-arrow {
    display: none;
  }
}
.WelcomePage {
    text-align: left;
    width: 800px;

}

.WelcomePage-container {
    padding-top: 25vh;
    /* padding-left: 12em; */

    /* padding-top: 150px; */
    padding-left: 100px;
}

.WelcomePage h1 {
    padding-bottom: 1%;
    color: #E6964A;
}

.WelcomePage-sub {
    /* width: 35vw; */
    width: 460px;
    font-family: 'DM_Sans-Regular';
    font-style: normal;
    letter-spacing: 0.02em;
    color: #E6964A;
}

@media screen and (max-width: 900px) {

    .WelcomePage {
        /* width: 90vw; */
        height:fit-content;
        padding: 25px 25px;
        /* margin-bottom: 20%; */
        background-color: var(--black);
    }

    .WelcomePage h1 {
        font-size: 2rem;
        /* margin-top: 1.2rem; */
        margin-top: 20px;
    }

    .WelcomePage-sub {
        width: 80vw;
    }

    .WelcomePage-container {
        /* padding-left: 1em; */
        width: 90vw;
        padding-left: 15px;
    }

    /* .WelcomePage-sub {
        width: 100%;
    } */
}
/* Add CSS Stuff here! */

.faq {
  height: 100vh;
  width: 2500px;
  margin-left: 400px;
  /* padding: 3rem 0; */
  text-align: left;
  color: var(--beige);
}

.faq .header-class {
  display: flex;
  margin: 0;

}

.faq .faq-container {
  padding-top: 25vh;
  /* padding-left: 8em; */
  margin-bottom: 0;
  padding-top: 150px;
  /* padding-left: 100px; */
}

.faq .faq-scale {
  /* transform: scale(0.8); */
  padding-top: 0;
}

.faq h1 {
  color: var(--beige);
  padding-bottom: 1%;
}

.relative {
  position: relative;
}

.parent {
  display: flex;
  /* flex-flow: row;
    flex: 0 0 auto; */
  width: 2000px;
}

.child {
  flex: 3;
  /* border: 2px solid white; */
}

.faq .faq-items {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 55vh;
  width: 35vw;
  column-gap: 0px;
  /* justify-content: space-around; */
}

.faq h2 {
  text-align: left;
  padding-bottom: 0.7rem;
  font-weight: bold;
  font-family: 'DM_Sans-Bold';
  font-size: 1.2rem;
  color: var(--beige);

}

#img {
  position: relative;
  width: 140%;
  align-self: center;
  right: 4rem;
}

.faq-subtitle {
  font-family: 'Inter';
  z-index: 10;
}

#hello-email {
  font-family: 'DM_Sans-Bold';
  text-decoration: none !important;
  color: var(--beige);
}

#sponsor-email {
  font-family: 'DM_Sans-Bold';
  color: var(--beige) !important;
  text-decoration: none !important;
}

#mentor-form {
  font-family: 'DM_Sans-Bold';
  color: var(--beige) !important;
  text-decoration: none !important;
}

@media screen and (max-width: 900px) {
  #video {
    width: 100%;
  }

  .faq {
    padding-left: 40px !important;
    margin: 0 !important;
    /* margin-top: 100px !important; */
    height: 2500px;
    padding-top: 25vh;
    padding-bottom: 10%;
    background-color: var(--lightBrown);
  }

  .faq-container {
    height: auto;
    padding: 0 !important;
    width: 80vw;
    display: flex;
    flex-flow: row wrap;
  }

  .faq .faq-items {
    display: flex !important;
    flex-flow: row wrap !important;
    width: 70vw;
  }

  .faq-item {
    margin-left: 0 !important;
  }

  .faq-subtitle {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 534px) {
  .faq-header h1 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 426px) {
  .faq-header h1 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 383px) {
  .faq-header h1 {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 342px) {
  .faq-header h1 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 320px) {
  .faq-header h1 {
    font-size: 1.4rem;
  }
}
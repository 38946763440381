.SponsorshipPage {
    text-align: left;
    width: 1000px;
    /* height: 100vh; */
    /* background-color: var(--black); */
    color: var(--brown);
}

.SponsorshipPage-Text {
    /* padding-top: 25vh;
    padding-left: 8em; */
    padding-top: 150px;
    padding-left: 100px;
}

.SponsorshipPage h1 {
    color: var(--brown);
    
}
.SponsorshipPage h2 {
    color: var(--brown);
    padding-bottom: 1%;
}

.SponsorshipPage-description {
    width: 460px;
    font-family: 'DM_Sans-Regular';
    font-size: 1rem !important;
    font-weight: 100;
    line-height: 1;
    color: var(--beige);
}

.partnership {
    margin: 1rem 0 0 0;
}

.sponsors-text {
    font-family: 'DM_Sans-Regular';
    /* margin: 0rem 12% 0rem 12%; */
    padding: 1rem 0 3rem 0;
}

.sponsors-text a {
    color: var(--black);
    text-decoration: none;
}

.sponsors-text a:hover {
    color: var(--black);
    text-decoration: underline;
}

.sponsors-items {
    margin: 2% 12% 0rem 12%;
    /* padding: 0 0 4rem 0; */
    /* display: flex; */
    flex-wrap: wrap;
}
.two-columns {
    display: flex;
    flex-wrap: wrap;
    margin-right: 4%;
    width: 100%;
}

.sponsors-name {
    font-family: 'DM_Sans-Regular';
    margin: 2px 0 1px 0;
    font-size: 30px;
}

.sponsors-giga {
    width: 100%;
}

.sponsors-img-giga {
    transition: 0.2s ease-in-out;
    /* max-width: 30vw; */
    height: 10vh;
    width: 300px  !important;
    margin: 5px;
    object-fit: cover;
    display: inline-block;
}

.sponsors-img-giga:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.sponsors-kilo {
    max-width: 100%;
    margin-right: 10%;
}
.sponsors-mega {
    max-width: 100%;
}

.sponsors-img-kilo {
    transition: 0.2s ease-in-out;
    /* max-width: 15vw; */
    max-height: 8vh;
    margin: 1rem 2rem 1rem 0;
    object-fit: cover;
}

.sponsors-img-kilo:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.sponsors-micro {
    transition: 0.2s ease-in-out;
    width: 100%;
    /* max-height: 30px; */
    margin: 1rem 2rem 1rem 0;
    object-fit: cover;
}

.sponsors-img-micro {
    transition: 0.2s ease-in-out;
    /* max-width: 10vw; */
    max-height: 6vw;
    margin: 1px 2% 0 0;
    object-fit: cover;
}

.sponsors-img-micro:hover {
    cursor: pointer;
    transform: scale(1.1);
}

@media screen and (max-width: 900px) {
    .SponsorshipPage {
        height: fit-content;
        /* margin-bottom: 20%; */
        background-color: #FFEECA;
    }

    .SponsorshipPage h1 {
        font-size: 2rem;
        /* margin-top: 20px; */
        color: var(--brown);
        padding-bottom: 3%;
    }

    .SponsorshipPage p {
        font-size: 0.9rem;
    }

    .SponsorshipPage {
        padding: 25px 25px;
    }

    .sponsors-text a {
        color: var(--orange);
        text-decoration: none;
    }
    
    .sponsors-text a:hover {
        color: var(--black);
        text-decoration: underline;
    }

    .SponsorshipPage-Text {
        padding-left: 15px;
        color: var(--brown);
        width: 70vw;
        font-size: 0.7rem;
    }

    .sponsors-heading {
        margin: 0;
        padding: 1rem 2rem;
    }

    .sponsors-name {
        font-size: 1.3rem;
        margin: 0.5rem 0;
        color: var(--brown) !important;
    }

    .sponsors-section {
        margin: 0 auto;
    }

    .sponsors-items {
        margin: 0rem 2rem;
        /* padding: 0 0 4rem 0; */
        /* display: flex; */
        flex-wrap: wrap;
    }

    .sponsors-giga,
    .sponsors-mega,
    .sponsors-kilo,
    .sponsors-micro
    .sponsors-partners {
        margin-left: 0;
        max-width: 100%;
    }

    .sponsors-img-micro {
        padding: 0 !important;
    }
    .sponsors-img-giga {
        /* max-height: 40vw; */
        /* width: 100%; */
        width: 300px !important;

    }
    .sponsors-img-kilo {
        /* max-height: 10vw; */
        width: 100px !important;
        max-height: 200px !important;
    }
    .sponsors-img-micro {
        width: 100px !important;
        max-height: 100px !important;
    }

    /* .SponsorshipPage-description {
        width: 100%;
    } */
}
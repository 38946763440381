@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap');

#starter-packs {
  color: #542c19;
  text-align: left;
  font-family: 'DM Sans', sans-serif;
  width: 2000px;
  padding: 3%;
  /* min-height: 100%; */
  padding-top: 25vh;
  padding-left: 8em;
  /* background-color: var(--black); */
  height: 100vh;
  margin-left: 200px;
}

h1 {
  color: #542c19;
}

#title {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  font-family: 'DM Sans', sans-serif;
  gap: 14px;
}

#last-link {
  display: flex;
  flex-direction: row;
  gap: 12px;
  border: 2px solid #542c19;
  border-radius: 50px;
  width: 200px;
  height: 45px;
}

#last-link img {
  margin-top: 12px;
  width: 20px;
  height: 20px;
}

#link-label {
  /* margin: auto; */
  margin-top: 12px;
  margin-left: 15px;
  line-height: 1.25rem;
  font-size: 15px;
}

#pack-title {
  font-weight: bold;
  font-size: 24px;
  text-decoration: none;
  color: inherit;
}

#pack-title:hover {
  color: #a83600;
}

#pack-link {
  text-decoration: none;
  color: inherit;
}

#pack-div {
  width: 30vw;

}

#desc {
  display: inline-block;
  white-space: normal;
  /* super important for text wrapping (for some reason) */
}

#sp-col {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 320px;
  gap: 30px;
}

#sp-table {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

#minified-link {
  font-weight: bold;
}

/* mobile */
@media screen and (max-width: 900px) {
  #starter-packs {
    padding-left: 40px;
    height: 1600px;
    color: var(--brown);
    margin-left: 0px;
    padding-top: 25vh;
    padding-bottom: 10%;
    background-color: #CF8B34;
  }

  #title {
    margin-bottom: 1em;
  }


  #starter-packs h1 {
    color: var(--brown) !important
  }

  #sp-col {
    flex-direction: row;
    width: 200px;
  }

  #pack-div #desc {
    width: 250px;
  }

  #nb-div {
    width: 300px;
  }

  #last-link {
    width: 40vw;
    gap: 1vw;
    border: 2px solid var(--brown);
  }

  #link-label {
    color: var(--brown);
    font-size: 0.8rem;
  }

  #last-link img {
    /* gap: 0px; */
    margin-top: 0.8em;
    width: 1em;
    height: 1em;
  }
}
.itinerary {
  text-align: left;
  width: 2000px;

  /* background-color: var(--black); */
  /* height: 100vh; */


}

.itinerary .header-class {
  display: flex;
  margin: 0;

}

.itinerary .itinerary-container {
  padding-top: 25vh !important;
  /* padding-left: 8em; */
  /* padding-top: 150px; */
  padding-left: 25px;
  margin-bottom: 0;


}

.itinerary .itinerary-scale {
  /* transform: scale(0.8); */
  padding-top: 0;
}


.itinerary h1 {
  color: var(--beige);
  padding-bottom: 1%;
}

.parent {
  display: flex;
  flex-wrap: wrap;
  /* flex-flow: row;
    flex: 0 0 auto; */
  width: 2000px;
}

.child-saturday {
  flex: 0.9;
  /* border: 2px solid white; */
}

.child-sunday {
  flex: 0.5;
  /* border: 2px solid white */
}

.itinerary h2 {
  text-align: left;
  padding-bottom: 0.7rem;
  font-weight: bold;
  font-family: 'DM_Sans-Bold';
  font-size: 1.2rem;
  color: var(--beige);

}

.itinerary-subtitle {
  font-family: 'DM_Sans-Bold';
  color: var(--beige);
}

.itinerary-bold {
  font-family: 'DM_Sans-Bold';
  font-size: 1rem !important;
  color: var(--beige);
}

.itinerary-regular {
  font-family: 'DM_Sans-Regular';
  font-size: 1rem !important;
  font-weight: 100;
  line-height: 0.75;
  color: var(--beige);
  /* padding-bottom: 3rem; */
}

.itinerary .itinerary-items {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 55vh;
  column-gap: 0px;

}

.itinerary .col {
  padding-bottom: 1.5rem;
}

.itinerary-header {
  font-size: 1.4rem;
}

.relative {
  position: relative;
}

.itinerary .btn {

  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  /* margin-top: 10px; */
  margin-bottom: 30px;
  border: 2px solid;
  color: var(--beige) !important;
}
.itinerary .btn a {
  color: var(--beige) !important;
}

.itinerary .btn-outline-success {
  color: var(--beige) !important;

  border-color: var(--beige);

}

.itinerary .btn-outline-success:hover {

  background-color: var(--orange);
  border-color: var(--beige);

}



@media screen and (max-width: 900px) {

  .itinerary {
    width: 100vw;
    /* display: block; */
    padding-bottom: 10%;
    background-color: var(--brown);
  }

  .itinerary-container {
    height: auto;
    padding: 0 !important;
    margin-left: 40px;
    width: 80vw;
    display: flex;
    flex-flow: row wrap;
    
  }

  .parent {
    display: flex !important;
    flex-flow: row wrap !important;
  }

  .child-saturday .child-sunday {
    width: 80vw;
  }

  .itinerary-items {
    width: 50vw;
    display: flex !important;
    flex-flow: row wrap !important;
    height: fit-content !important;
    margin-bottom: 20px;
  }

  .itinerary p {
    line-height: 2;
  }
}
.navbar {
  width: 85%;
  top: 2vw;
  position: fixed;
  font-size: 125%;
  color: #F5AD40;
  transition: 1s;
  
}

.alt-color {
  color: #542C19;
}


#navbar-logo-img {
  max-width: 90px;
  max-height: 90px;
  width: auto;
  height: auto;
  transition: 0.2s;

}

a {
  text-decoration: none;
  color: #542C19;
  cursor: pointer;
  white-space: nowrap;
}

a:hover {
  color: #542C19;
}

#navbar-logo-img:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.navbar-links {
  width: 100vw;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
}

.nav-join-login {
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  border-style: transparent;
  border-width: 2px;
  border-radius: 25px;
  color: #542C19;
  background: #F5AD40;
}

/*
with smooth transition
*/
.nav-item:hover {
  font-size: 125%;
  transition: 0.2s;
}

#mlh-trust-badge {
  right: 3%;
}

.hamburger {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;
}

.burger {
  width: 1.8rem;
  height: 0.15rem;
  background-color: var(--orange);
  transform-origin: 1px;
  transition: all 0.3s linear;
}

.hammie {
  display: none;
}

@media screen and (max-width: 500px) {

  .navbar {
    width: 100vw;
    z-index: 10;
    color: #F5AD40;
  }

  .hammie {
    margin-top: 0.5em;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    display: flex !important;
    flex-flow: row nowrap !important;
    justify-content: space-between !important;
    align-items: center !important;
    z-index: 10;
  }


  .navbar-dropdown {
    background-color: rgba(0, 0, 0, 0.399);
    width: 100%;
    margin-top: 10px;
    padding-top: 20px;
    padding-bottom: 35px;
    padding-left: 50px;
    padding-right: 50px;
    z-index: 10;
  }

  #navbar-logo-img {
    max-width: 30px;
    max-height: auto;
  }

  .ham {
    float: right;
    z-index: 10;
  }

  .navbar-links {
    display: none;
  }

  .landing h1 {
    margin-left: 0.5em;
    font-size: 64px;
  }

  .landing h2 {
    margin-top: 2em;
    margin-left: 0.5em;
    /* font-size: 64px; */
  }

  .mlh-wrapper {
    display: none;
  }
}
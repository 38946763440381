:root {
  --black: #170B18;
  --brown: #542C19;
  --lightBrown: #BA4E15;
  --orange: #F5AD40;
  --yellow: #FAE847;
  --beige: #FFEECA;
}




@font-face {
    font-family:"ABCMaxiRoundVariable";  
    src: local("ABCMaxiRoundVariable"),
      url("./fonts/ABCMaxiRoundVariable.ttf") format("truetype");

    font-weight: normal;
    font-style: normal;

  }

@font-face {
    font-family:"DM_Sans-Bold";  
    src: local("DM_Sans-Bold"),
      url("./fonts/DM_Sans/DMSans-Bold.ttf") format("truetype");

    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family:"DM_Sans-Medium";
    src: local("DM_Sans-Medium"),
      url("./fonts/DM_Sans/DMSans-Medium.ttf") format("truetype");

    font-weight: medium;
    font-style: normal;
}

@font-face {
    font-family:"DM_Sans-Regular";  
    src: local("DM_Sans-Regular"),
      url("./fonts/DM_Sans/DMSans-Regular.ttf") format("truetype");

    font-weight: normal;
    font-style: normal;
}

h1 {
    font-family: 'ABCMaxiRoundVariable';
    font-size: 55px;
    color: var(--lightBrown);
    
}
h2 {
    font-family: 'ABCMaxiRoundVariable'; 
}
h3 {
    font-family: 'ABCMaxiRoundVariable';
}
h4, h5, h6 {
    font-family: 'DM_Sans-Bold';
}

p {
    font-family: 'DM_Sans-Regular';
    font-size: medium;
}
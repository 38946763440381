.faq-item {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
    width: fit-content;
     
    display: inline-block;
   
}

.faq-item .question {
    font-family: 'DM_Sans-Bold';  
    font-size: 1rem !important;
    color: var(--beige);
}

.faq-item .info {
    font-family: 'DM_Sans-Regular';
    font-size: 1rem !important;
    font-weight: 100;
    line-height: 1.2;
    color: var(--beige);
    margin-bottom: 2%;
}
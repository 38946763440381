.itinerary-item {
    margin-top: 10px;
    margin-bottom: 10px;
    width: fit-content;
    margin-right: 3%;
    display: inline-block;

}

.itinerary-item .time {
    font-family: 'DM_Sans-Bold';
    font-size: 1rem !important;
    color: var(--beige);
}

.itinerary-item .title {
    font-family: 'DM_Sans-Regular';
    font-size: 1rem !important;
    font-weight: 100;
    line-height: 0.75;
    color: var(--beige);
    margin-bottom: 2%;
}

.itinerary-item .location {
    font-family: 'DM_Sans-Regular';
    font-size: 1rem !important;
    font-weight: 100;
    line-height: 0.75;
    color: var(--beige);
}

@media screen and (max-width: 900px) {
    .itinerary-item {
        width: 300px;
    }
}
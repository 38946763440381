.footer {
    text-align: left;
    background-color: #A84C1B;
    width: 350px;
    height: 100vh;

}

.footer h1 {
    color: var(--beige);
    font-size: 4rem;
}

.footer h4 {
    color: var(--beige)
}

.footer h5 {
    color: var(--beige)
}

.footer p {
    color: var(--beige)
}

.footer a {
    text-decoration: none;
    color: var(--beige)
}

.header {
    padding-top: 5%;
    margin-left: 6%;
    margin-bottom: 4%;
}

.links {
    margin-left: 8%;
    margin-top: 2%;
}

.message {
    margin-left: 8%;
    margin-top: 2%;
}

.text {
    font-size: 14px;
}

.subtitle {
    font-size: 10px;
}

.footer .position {
    margin-left: 5%;
}

@media screen and (max-width: 900px) {
    .footer {
        width: 100vw;
        height: 600px;
    }

    .footer h1 {
        font-size: 24px !important;
    }

    h4 {
        font-size: 14px;
    }

    h5 {
        font-size: 16px;
        margin-top: 10px;
    }

    .text {
        margin: 0;
    }

    .footer-group {
        display: flex !important;
        flex-flow: row wrap !important;
        width: 40vw !important;
    }

    .links {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 8%;
    }

    .message {
        padding-bottom: 20px !important;
        margin-left: 8%;
    
    }
}
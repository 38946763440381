.AboutPage {
    text-align: left;
    width: 1000px;
}

.AboutPage-Text {
    /* padding-top: 150px; */
    padding-left: 100px; 
    padding-top: 25vh;
    /* padding-left: 8em; */
    
}

.AboutPage h1 {
    color: var(--orange);
    padding-bottom: 1%;

}

.AboutPage-description {
    width: 600px;
    font-family: 'DM_Sans-Regular';
    font-size: 1rem !important;
    font-weight: 100;
    line-height: 1.2;
    color: var(--orange);
}

@media screen and (max-width: 900px) {
    .AboutPage {
        height: 900px;
        padding: 20px 20px;
        background-color: var(--black);
    }

    .AboutPage h1 {
        font-size: 2rem;
        margin-top: 20px;

    }

    .AboutPage-Text {
        padding-left: 20px;
        padding-top: 18vh !important;
    }

    .AboutPage-description {
        width: 80vw;
    }
}